.asked-component{
  .p-accordion .p-accordion-header .p-accordion-header-link{
    border: unset;
    font-weight: 500;
    color: black;
  }
  .p-accordion .p-accordion-content{
    border: unset;
  }
  .p-accordion .p-accordion-header .p-accordion-header-link .p-accordion-toggle-icon{
    color: #80ACD8;
  }
  .p-accordion .p-accordion-header:not(.p-disabled).p-highlight .p-accordion-header-link{
    background-color: #DEEBFA;
    border-radius: 10px;
  }
  
.p-accordion .p-accordion-header .p-accordion-header-link .p-accordion-toggle-icon{
  color: #5471B3;
}
}
