
.p-component:not(.asked),.scroll-view {
    animation: appear linear ;
    animation-timeline: view();
    animation-range: entry 0% ;
  }

  @keyframes appear {

    from{
      opacity: 0;
      transform: translateY(100px);

    }
    to{
      opacity: 1;
      transform: translateY(0px);
    }

  }
