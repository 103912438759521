.hide-shadow {
  box-shadow: unset;
}
.p-card-footer {
  padding: 0;
}
.p-card-content {
  padding: 0;
}
.p-card-body {
  padding: 0;
  .p-card-title {
    font-weight: 400;
    margin: 10px 0 0 0;
  }
}
.project-partner-component {
  .p-card-header {
    padding: 7px;
  }
  .p-card-body {
    padding: 10px 7px 20px 13px;
  }
}

.what-is-new-component {
  .p-card-header {
    padding: 7px;
  }
  .p-card-body {
    padding: 0 15px;
    span {
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
    p {
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
    .p-card-title {
      font-size: 17px;
      font-weight: 400;
    }
    .p-card-content {
      padding: 0;
    }
    .p-card-footer {
      flex: 1;
      margin-bottom: 10px;
    }
  }
}


.solutions_show_Layer:hover .text_hover_card,
.services_show_Layer:hover .text_hover_card
 {
  text-decoration: underline;
  color: #5471b3 !important;
}
