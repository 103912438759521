.solution-component{
  p-iconfield{
    width: 100%;
  }
  .p-inputtext{
    border: unset;
    width: 100%;
  }
  .p-inputtext:focus{
    box-shadow:none ;
    border-color:unset ;
  }
}
.p-inputtext,
.p-inputnumber {
  &.ng-touched.ng-invalid {
    border-color:#e24c4c;
  }
}
