:root {
    @media only screen and (max-width: 992px) {
      font-size: 80%; // 1rem = 8px; 8px/16px = 50%
    }
  }
// // BeVietnamPro
// @font-face {
//   font-family: 'BeVietnamPro';
//   src: url(/fonts/BeVietnamPro-Bold.woff2) format('woff2');
//   font-weight: 800; /* Bold */
//   font-display: swap;
// }
// @font-face {
//   font-family: 'BeVietnamPro';
//   src: url(/fonts/BeVietnamPro-ExtraBold.woff2) format('woff2');
//   font-weight: 900; /* ExtraBold */
//   font-display: swap;
// }
// @font-face {
//   font-family: 'BeVietnamPro';
//   src: url(/fonts/BeVietnamPro-Medium.woff2) format('woff2');
//   font-weight: 500; /* Medium */
//   font-display: swap;
// }
// @font-face {
//   font-family: 'BeVietnamPro';
//   src: url(/fonts/BeVietnamPro-Regular.woff2) format('woff2');
//   font-weight: 400; /* Regular */
//   font-display: swap;
// }
// @font-face {
//   font-family: 'BeVietnamPro';
//   src: url(/fonts/BeVietnamPro-SemiBold.woff2) format('woff2');
//   font-weight: 600; /* SemiBold */
//   font-display: swap;
// }
// @font-face {
//   font-family: 'BeVietnamPro';
//   src: url(/fonts/BeVietnamPro-Light.woff2) format('woff2');
//   font-weight: 300; /* light */
//   font-display: swap;
// }
// @font-face {
//   font-family: 'BeVietnamPro';
//   src: url(/fonts/BeVietnamPro-ExstraLight.woff2) format('woff2');
//   font-weight: 200; /* Exstra light */
//   font-display: swap;
// }
// @font-face {
//   font-family: 'BeVietnamPro';
//   src: url(/fonts/BeVietnamPro-Thin.woff2) format('woff2');
//   font-weight: 100; /* thin */
//   font-display: swap;
// }
