@use "./styles/index" as *;

/* You can add global styles to this file, and also import other style files */

body,.p-component{
  font-family: "Be Vietnam Pro";
  scroll-behavior: smooth;
  &:has(.loading-overlay) {
    overflow: hidden; 
  }
}

