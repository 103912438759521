swiper-container{
  // display: flex;
}
 .swiper-pagination {
  display: flex;
  gap: 5px;
  justify-content: end;
  padding-right: 10px;
  span.swiper-pagination-bullet {
    cursor: pointer;
    display: flex;
    border-radius: 5px;
    background-color: #c4c4c4;
    transition: 0.6s;
    padding: 1px;
    width: 30px;
  }
  span.swiper-pagination-bullet-active {
    width: 50px;
    background-color: black;
  }
}
